@import 'styles/variables';
@import 'styles/extensions';

.use-cases {
  background: 
  radial-gradient(60% 60% at 90% 10%, #D052F7 -28%, rgba(217, 217, 217, 0) 100%), $black;
  background-repeat: no-repeat, no-repeat;
  background-position: 100% 0,  0 0;
  background-size: 50% 50%, 100%;

  @media screen and (max-width: 640px) {
    background-size: 100% 50%, 100%;
  }

  &__wrapper {
    @extend %wrapper;
    max-width: 1340px;
    padding-top: 100px;
    padding-bottom: 150px;
    overflow: hidden;
    color: $black;

    @media (min-width: 2560px) {
      max-width: calc(1340px * #{$zoom});
      padding-bottom: calc(150px * #{$zoom});
    }

    @media screen and (max-width: 767px) {
      padding-bottom: 100px;
    }

    @media screen and (max-width: 475px) {
      padding-right: 0;
      padding-bottom: 70px;
      padding-left: 0;
    }

    h2 {
      @extend %wrapper;
      max-width: 1140px;
      padding-right: 0;
      padding-bottom: 90px;
      padding-left: 0;
      color:  $white;
      font-weight: normal;
      font-size: 60px;
      font-family: $secondary-font;
      line-height: 64px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: uppercase;

      span {
        color: $violet;
        font-weight: normal;
      }
  
      @media (min-width: 2560px) {
        max-width: calc(1140px * #{$zoom});
        padding-bottom: calc(90px * #{$zoom});
        font-size: calc(60px * #{$zoom});
        line-height: calc(64px * #{$zoom});
      }
  
      @media screen and (max-width: 767px) {
        flex-direction: column-reverse;
        gap: 40px;
        font-size: 48px;
        line-height: 56px;
      }

      @media screen and (max-width: 475px) {
        padding-right: 20px;
        padding-left: 20px;
      }
  
      @media screen and (max-width: 450px) {
        padding-bottom: 70px;
        font-size: 32px;
        line-height: 36px;
      }
    }

    .sub-title-wrapper {
      @extend %wrapper;
      max-width: 1140px;
      padding-right: 0;
      padding-left: 0;

      @media (min-width: 2560px) {
        max-width: calc(1140px * #{$zoom});
      }

      @media screen and (max-width: 475px) {
        padding-right: 20px;
        padding-left: 20px;
      }
    }

    .sub-title {
      position: relative;
      display: inline-block;
      margin-bottom: 55px;
      color: #F1F1F4;
      font-weight: normal;
      font-size: 32px;
      font-family: $secondary-font;
      line-height: 36px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: uppercase;
  
      @media (min-width: 2560px) {
        font-size: calc(32px * #{$zoom});
        line-height: calc(36px * #{$zoom});
      }
  
      @media (max-width: 475px) {
        margin-bottom: 25px;
        font-size: 18px;
      }
  
      &::after {
        position: absolute;
        top: -5px;
        left: 0;
        width: 100%;
        height: 2px;
        background: #F1F1F4;
        transition: width 0.3s;
        content: '';
      }
    }

    h3 {
      @extend %wrapper;
      max-width: 1140px;
      padding-right: 0;
      padding-bottom: 35px;
      padding-left: 0;
      color: $white;
      font-weight: normal;
      font-size: 40px;
      font-family: $secondary-font;
      line-height: 48px;
      letter-spacing: 0px;
      text-align: left;
      text-transform: uppercase;
  
      @media (min-width: 2560px) {
        max-width: calc(1140px * #{$zoom});
        padding-bottom: calc(35px * #{$zoom});
        font-size: calc(40px * #{$zoom});
        line-height: calc(48px * #{$zoom});
      }
  
      @media screen and (max-width: 767px) {
        font-size: 32px;
        line-height: 36px;
      }
  
      @media screen and (max-width: 450px) {
        font-size: 24px;
        line-height: 24px;
      }
    }

    .carousel.carousel-slider {
      .control-dots {        
        .dot {
          width: 13px;
          height: 8px;
          border-radius: 0;
          box-shadow: none;

          @media (min-width: 2560px) {
            width: calc(13px * #{$zoom});
            height: calc(8px * #{$zoom});
          }

          &:hover {
            background: $violet;
          }

          &.selected {
            background: $violet;
          }
        }
      }

      .control-prev.control-arrow {
        &:before {
          width: 23px;
          height: 40px;
          background-image: url('../../../../../asset/resource/chevron.png');
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: 100%;
          border: none;

          @media (min-width: 2560px) {
            width: calc(23px * #{$zoom});
            height: calc(40px * #{$zoom});
          }
        }
      }

      .control-next.control-arrow {
        &:before {
          width: 23px;
          height: 40px;
          background-image: url('../../../../../asset/resource/chevron.png');
          background-repeat: no-repeat;
          background-position: 50%;
          background-size: 100%;
          border: none;
          transform: rotate(180deg);

          @media (min-width: 2560px) {
            width: calc(23px * #{$zoom});
            height: calc(40px * #{$zoom});
          }
        }
      }

      .control-arrow {
        width: 33px;
        filter: invert(1);

        @media (min-width: 2560px) {
          width: calc(33px * #{$zoom});
        }
        
        @media (max-width: 640px) {
          display: none;
        }
      }
    } 

    .carousel .slide {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  &__slide {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1220px;
    margin: 0 auto;
    padding: 0 40px 80px 50px;
    color: $white;

    @media (min-width: 2560px) {
      max-width: calc(1190px * #{$zoom});
      height: 0 calc(40px * #{$zoom}) calc(80px * #{$zoom}) calc(50px * #{$zoom});
    }

    @media (max-width: 640px) {
      flex-direction: column;
      padding: 0 10px 80px 10px;
    }
    
    @media (min-width: 640px) and (max-width: 880px) {
      flex-direction: column;
      max-width: 95%;
    }

    article,
    figure {
      flex-basis: 50%;
      gap: 50px;
      max-width: 585px;

      @media (min-width: 2560px) {
        max-width: calc(585px * #{$zoom});
      }
    }

    article {
      text-align: left;

      @media (max-width: 880px) {
        flex-basis: 100%;
      }

      @media (max-width: 475px) {
        padding-right: 20px;
        padding-left: 20px;
      }
    }

    figure {
      overflow: hidden;

      img {
        @media (min-width: 1150px) {
          width: 625px!important;
        }

        @media (max-width: 880px) {
          margin-top: 30px;
        }

        @media (min-width: 880px) and (max-width: 1149px) {
          width: 125% !important;
        }
      }
    }

    span {
      padding-bottom: 35px;      
      font-weight: 700;

      @media (min-width: 2560px) {
        padding-bottom: calc(35px * #{$zoom});
        font-size: calc(16px * #{$zoom});
      }
    }

    p {
      width: 90%;
      line-height: 24px;

      @media (min-width: 2560px) {
        font-size: calc(16px * #{$zoom});
        line-height: calc(24px * #{$zoom});
      }
    }
  }
}

svg {
  position: relative;
  flex: 1;
	transition: all 0.5s ease-in-out;
}

svg .ekg {
  transform: rotate(180deg);
  transform-origin: center center;
  opacity: 0;
  animation: ekg 3.5s linear forwards infinite;
	fill: transparent;
	stroke: url(#paint0_radial_21696_33838);
	stroke-width: 1;
	stroke-linecap: round;
	stroke-linejoin: miter;
	stroke-dasharray: 0, 5671;
}

@keyframes ekg {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    stroke-dasharray: 2800, 5671;
  }
  99% {
    stroke-dasharray: 2800, 5671;
    opacity: 0;
  }
  100% {
    stroke-dasharray: 2800, 5671;
  }
} 