@import 'styles/variables';
@import 'styles/extensions';

.form-hubspot {
  &__form-wrapper {
    flex-basis: 65%;
    max-width: 685px;
    margin-bottom: 50px;

    @media (min-width: 2560px) {
      flex-basis: calc(65% * #{$zoom});
      max-width: calc(685px * #{$zoom});
    }

    .form-box {      
      &__row {
        display: flex;
        justify-content: space-between;

        &_without-flex {
          display: block;
        }

        @media (max-width: 475px) {
          display: block;
        }
      }

      &__col {
        flex-basis: 49%;
      }  
      
      &__description {
        margin-top: 40px;
        font-size: 16px;

        a {
          color: $white;
          font-weight: 700;
          text-decoration: underline;
        }
        
        @media (min-width: 2560px) {
          font-size: calc(16px * #{$zoom});
          line-height: calc(18px * #{$zoom});
        }
      }
    }

    .form-input {
      &__text,
      &__textarea {
        &::-webkit-input-placeholder {
          color: $white;
          font-weight: 800;
          font-family: $basic-font;
          text-transform: none;
        }
    
        &::-moz-placeholder {
          color: $white;
          font-weight: 800;
          font-family: $basic-font;
          text-transform: none;
        }
    
        &:-moz-placeholder {
          color: $white;
          font-weight: 800;
          font-family: $basic-font;
          text-transform: none;
        }
    
        &:-ms-input-placeholder {
          color: $white;
          font-weight: 800;
          font-family: $basic-font;
          text-transform: none;
        }
      }

      &__textarea {
        min-height: 80px;

        @media (max-width: 475px) {
          min-height: 120px;
          margin-bottom: 30px;
        }
      }

      &__checkbox-label {
        color: $white;

        &::before {
          color: $black;
          background-color: $white;
          border-color: $white;
        }

        &:hover, &:focus {
          &::before {
            color: $black;
            background-color: $violet;
            border-color: $violet;
          }
        }
      }
    }
  }

  &__form-title {
    margin-bottom: 10px;
    font-weight: 800;
    font-size: 32px;
    font-family: $secondary-font;
    line-height: 36px;
    text-transform: uppercase;

    @media (max-width: 475px) {
      font-size: 24px;
      line-height: 32px;
    }

    @media (min-width: 2560px) {
      font-size: calc(32px * #{$zoom});
      line-height: calc(36px * #{$zoom});
    }
  }

  &__form-description {
    margin-bottom: 50px;
    
    @media (min-width: 2560px) {
      font-size: calc(16px * #{$zoom});
      line-height: calc(20px * #{$zoom});
    }
  }

  &__form-button {
    @extend %button-colored;
    width: 100%;
    max-width: 376px;
    height: 48px;
    margin-left: 0;
    color: $black;
    line-height: 48px;
    background: linear-gradient(90deg, $white 0%, $white 100%);

    @media (min-width: 2560px) {
      max-width: calc(376px * #{$zoom});
      height: calc(48px * #{$zoom});
      font-size: calc(18px * #{$zoom});    
      line-height: calc(48px * #{$zoom});   
    }

    &:hover {
      color: $white;
      background: linear-gradient(90deg, $violet 0%, $violet 100%);
    }
  }

  &__success {
    margin-bottom: 300px;

    @media (min-width: 2560px) {
      margin-bottom: calc(300px * #{$zoom});
    }

    @media (max-width: 1024px) {
      margin-top: 100px;
      margin-bottom: 150px;
    }

    @media (max-width: 475px) {
      text-align: center;
    }
  }
} 