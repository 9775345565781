@import 'styles/variables';
@import 'styles/extensions';

.how-it-works {
  position: relative;

  &::before {
    position: absolute;
    right: 0;
    bottom: 5%;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(50% 12% at 55% 87%, rgba(208, 82, 247, 0.6) 0%, rgba(217, 217, 217, 0) 100%);
    transform: rotate(-15deg);
    content: '';
  }

  &__wrapper {
    @extend %wrapper;
    max-width: 1140px;
    padding-top: 100px;
    padding-bottom: 150px;

    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
    }
  }

  h2 {
    @extend %wrapper;
    max-width: 1140px;
    padding-right: 0;
    padding-bottom: 90px;
    padding-left: 0;
    font-weight: normal;
    font-size: 60px;
    font-family: $secondary-font;
    line-height: 64px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
      padding-bottom: calc(90px * #{$zoom});
      font-size: calc(60px * #{$zoom});
      line-height: calc(64px * #{$zoom});
    }

    @media screen and (max-width: 767px) {
      font-size: 48px;
      line-height: 56px;
    }

    @media screen and (max-width: 450px) {
      padding-bottom: 40px;
      font-size: 32px;
      line-height: 36px;
    }

    span {
      color: $violet;
    }
  }

  .sub-title {
    position: relative;
    display: inline-block;
    margin-bottom: 55px;
    font-weight: normal;
    font-size: 32px;
    font-family: $secondary-font;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      font-size: calc(32px * #{$zoom});
      line-height: calc(36px * #{$zoom});
    }

    @media (max-width: 475px) {
      margin-bottom: 25px;
      font-size: 18px;
    }

    &::after {
      position: absolute;
      top: -5px;
      left: 0;
      width: 100%;
      height: 2px;
      background: $white;
      transition: width 0.3s;
      content: '';
    }
  }

  h3 {
    @extend %wrapper;
    max-width: 1140px;
    padding-right: 0;
    padding-bottom: 20px;
    padding-left: 0;
    font-weight: normal;
    font-size: 24px;
    font-family: $secondary-font;
    line-height: 125%;
    letter-spacing: 0px;
    text-align: left;
    
    @media (min-width: 2560px) {
      font-size: calc(24px * #{$zoom});
    }
    
    @media screen and (max-width: 767px) {
      font-size: 48px;
      line-height: 56px;
    }
    
    @media screen and (max-width: 450px) {
      padding-bottom: 20px;
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__blocks {
    max-width: 1140px;
    margin: 0 auto;

    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
    }
  }

  &__block {
    position: relative;
    display: flex;
    gap: 30px;
    justify-content: space-between;
    margin-bottom: 200px;

    div {
      flex-basis: 50%;
    }

    @media (min-width: 2560px) {
      margin-bottom: calc(250px * #{$zoom});
    }

    &:last-child {
      margin-bottom: 0!important;
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      figure {
          img {
            justify-self: right;

            @media (max-width: 767px) {
              justify-self: center;
            }
        }
      }

      @media (max-width: 767px) {
        flex-direction: column;
      }
    }

    article,
    figure {
      flex-basis: 50%;
    }

    figure {
      display: grid;

      img {
        align-self: center;
        justify-self: left;
        width: 100%;
        max-width: 582px;

        @media (min-width: 2560px) {
          max-width: calc(582px * #{$zoom});
        }

        @media (max-width: 767px) {
          justify-self: center;
        }

        @media (max-width: 475px) {
          max-width: 100%;
        }
      }
    }

    article {
      max-width: 480px;

      @media (min-width: 2560px) {
        max-width: calc(480px * #{$zoom});
        font-size: calc(16px * 1.44);
      }

      @media (max-width: 767px) {        
        max-width: 100%;
        margin-bottom: 40px;
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 200px;
    }

    @media (max-width: 475px) {
      flex-direction: column;
      margin-bottom: 120px;
    }

    .how-to-use__image {
      transform: translateY(500px);
      transform-origin: 0 100%;
      opacity: 0;

      img {
        transform: scale(0.8);
        transition: transform 1s ease 1.5s;
      }
      
      @media (min-width: 2560px) {
        transform: translateY(calc(-500px * #{$zoom}))!important;
      }
    }
  }

  &__video {
    display: flex;
    gap: 40px;
    justify-content: space-between;
    padding-top: 150px;

    @media (max-width: 580px) {
      flex-direction: column;
    }

    & > div {
      flex: 1;
    }

    figure {
      display: grid;

      img {
        width: 100%;
        max-width: 582px;

        @media (min-width: 2560px) {
          width: calc(582px * #{$zoom});
        }
      }

      figcaption {
        padding-top: 24px;
        color: $white;
        font-size: 24px;
        font-family: $secondary-font;
        font-style: normal;
        line-height: 125%;

        @media (min-width: 2560px) {
          padding-top: calc(24px * #{$zoom});
          font-size: calc(24px * 1.44);
        }
      }
    }
  }

  &__video-image-wrapper {
    position: relative;

    &:hover {
      opacity: 0.7;
    }

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 74px;
      height: 74px;
      background-image: url('../../../../../asset/resource/play-button.png');
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 100%;
      border: none;
      transform: translate(-50%, -50%);
      content: '';

      @media (min-width: 2560px) {
        width: calc(74px * #{$zoom});
        height: calc(74px * #{$zoom});
      }

      @media (max-width: 580px) {
        width: 44px;
        height: 44px;
      }
    }
  }

  &__video-link {
    display: inline-block;
    text-decoration: none;
  }
}